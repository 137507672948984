import React, { useState, useRef, useContext } from 'react'
import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import { useForm, Controller } from 'react-hook-form'
import MenuItem from '@material-ui/core/MenuItem'
import Typography from '@material-ui/core/Typography'
import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import CardContent from '@material-ui/core/CardContent'
import Dialog from '@material-ui/core/Dialog'
import CircularProgress from '@material-ui/core/CircularProgress'
import MuiDialogTitle from '@material-ui/core/DialogTitle'
import MuiDialogContent from '@material-ui/core/DialogContent'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import TablePagination from '@material-ui/core/TablePagination'
import Snackbar from '@material-ui/core/Snackbar'
import MuiAlert from '@material-ui/lab/Alert'
import Divider from '@material-ui/core/Divider'
import API from '../../utils/API'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import styled from 'styled-components'
import Tooltip from '@material-ui/core/Tooltip'
import Link from '@material-ui/core/Link'
import { AuthContext } from '../../context/AuthContext'

const useStyles = makeStyles((theme) => ({
  form: {
    width: '100%' // Fix IE 11 issue.
  },
  submit: {
    margin: theme.spacing(2, 0, 0)
  }
}))

const DialogTitle = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2)
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500]
  }
}))((props) => {
  const { children, classes, onClose, ...other } = props
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      {children}
      {onClose ? (
        <IconButton aria-label='close' className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  )
})

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2)
  }
}))(MuiDialogContent)

const Alert = (props) => {
  return <MuiAlert elevation={6} variant='filled' {...props} />
}

const StyledClipboardText = styled.span`
  cursor: pointer;
  background-color: ${props => props.highlight && '#10FF00'}
`

const StyledTable = styled(Table)`
  border: 1px solid #000;
`

const StyledTableHeadCell = styled(TableCell)`
  background-color: ${props => props.theme.palette.primary.main};
  color: ${props => props.theme.palette.common.white};
  border-bottom: 1px solid #000;
`

const StyledTableBodyCell = styled(TableCell)`
  border: 1px solid #000;
`

export default function GenerateCodes (props) {
  const { authState, authDispatch } = useContext(AuthContext)
  const classes = useStyles()
  const [loading, setLoading] = useState(false)
  const [openDialog, setOpenDialog] = useState(false)
  const [downloadUrl, setDownloadUrl] = useState('')
  const [toaster, setToaster] = useState({
    open: false,
    vertical: 'top',
    horizontal: 'right'
  })
  const [tooltipText, setTooltipText] = useState('')
  const [responseData, setResponseData] = useState([])
  const [error, setError] = useState(undefined)
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const { handleSubmit, control, errors, reset } = useForm({
    defaultValues: {
      denomination: '',
      quantity: '',
      expiry: 1095
    }
  })

  const scratchValueRef = useRef()
  const quantityRef = useRef()
  const expiryRef = useRef()
  const columns = [
    { id: 'serialNumber', label: 'Serial Number' },
    { id: 'pin', label: 'Pin' }
  ]
  const scratchValueVND = [
    {
      label: '50,000.00',
      value: 50000.00
    },
    {
      label: '80,000.00',
      value: 80000.00
    },
    {
      label: '100,000.00',
      value: 100000.00
    },
    {
      label: '200,000.00',
      value: 200000.00
    },
    {
      label: '300,000.00',
      value: 300000.00
    },
    {
      label: '500,000.00',
      value: 500000.00
    }
  ]
  const scratchValueTHB = [
    {
      label: '100.00',
      value: 100.00
    },
    {
      label: '500.00',
      value: 500.00
    },
    {
      label: '1000.00',
      value: 1000.00
    },
    {
      label: '3000.00',
      value: 3000.00
    }
  ]
  const scratchValueOptions = authState.agentUser && authState.agentUser.currency === 1 ? scratchValueVND : scratchValueTHB

  function handleCloseDialog () {
    setOpenDialog(false)
    reset()
  }

  function handleChangePage (event, newPage) {
    setPage(newPage)
  }

  function handleChangeRowsPerPage (event) {
    setRowsPerPage(+event.target.value)
    setPage(0)
  }

  function handleOnCopyClipboard (text) {
    setTooltipText(text)
  }

  function handleDownloadGeneratedCodes () {
    const csvRows = []

    // get the headers
    const headers = Object.keys(responseData[0])
    // Converts headers to Pascal Case
    const newHeaders = headers.reduce((accu, curr) => {
      let newCurr = ''
      if (curr) {
        newCurr = curr.replace(/([A-Z])/g, ' $1') // insert a space before all caps
          .replace(/^./, (str) => { return str.toUpperCase() }) // uppercase the first character
        accu.push(newCurr)
      }
      return accu
    }, [])

    csvRows.push(newHeaders.join(','))

    // loop over the rows
    for (const row of responseData) {
      const rows = headers.map(header => {
        return '="' + row[header] + '"'
      })
      csvRows.push(rows)
    }

    // download the csv table
    const csvTable = csvRows.join('\n')
    const blob = new Blob([csvTable], { type: 'text/csv' }) // eslint-disable-line
    const url = window.URL.createObjectURL(blob)
    setDownloadUrl(url)
  }

  async function onSubmit (values) {
    setLoading(true)
    try {
      const response = await API({
        method: 'POST',
        url: '/scratchcard/generate',
        data: {
          quantity: parseInt(values.quantity),
          denomination: parseFloat(values.denomination),
          expiry: values.expiry
        }
      })
      setLoading(false)
      if (response !== undefined) {
        setOpenDialog(true)
        setToaster({ ...toaster, open: true })
        setResponseData(response.data.data.scratchCards)
        authDispatch({
          type: 'UPDATE_BALANCE',
          payload: {
            endBalance: response.data.data.endBalance
          }
        })
      }
    } catch (error) {
      setLoading(false)
      if (error && error.response) {
        setError({
          name: error.response.data.title,
          message: error.response.data.errors.Error.toString()
        })
      } else {
        setError({
          name: error.name,
          message: error.message
        })
      }
      setToaster({ ...toaster, open: true })
    }
  }

  return (
    <Grid container spacing={4}>
      <Grid item md={6}>
        <Card>
          <CardHeader
            title='Generate Codes'
          />
          <Divider />
          <CardContent>
            <form className={classes.form} onSubmit={handleSubmit(onSubmit)}>
              <Controller
                as={<TextField inputRef={scratchValueRef} />}
                control={control}
                rules={{ required: true }}
                onFocus={() => scratchValueRef.current.focus()}
                name='denomination'
                fullWidth
                variant='outlined'
                margin='normal'
                id='denomination'
                label='Scratch Value'
                select
                disabled={loading || openDialog}
                error={Object.prototype.hasOwnProperty.call(errors, 'denomination')}
                helperText={errors.denomination?.type === 'required' && 'Please input scratch value'}
              >
                {
                  scratchValueOptions.map(option => (
                    <MenuItem key={option.value} value={option.value}>
                      {
                        option.label
                      }
                    </MenuItem>
                  ))
                }
              </Controller>
              <Controller
                as={<TextField inputRef={quantityRef} />}
                control={control}
                rules={{ required: true, max: 100, min: 1 }}
                onFocus={() => quantityRef.current.focus()}
                onKeyDown={e => e.which === 69 && e.preventDefault()}
                variant='outlined'
                margin='normal'
                type='number'
                fullWidth
                name='quantity'
                label='Quantity (Max: 100)'
                id='quantity'
                disabled={loading || openDialog}
                error={Object.prototype.hasOwnProperty.call(errors, 'quantity')}
                helperText={
                  (errors.quantity?.type === 'required' && 'Please input scratch value') ||
                  (errors.quantity?.type === 'max' && 'Your input exceed max number') ||
                  (errors.quantity?.type === 'min' && 'Your input less than min number')
                }
              />
              <Controller
                as={<TextField inputRef={expiryRef} />}
                control={control}
                rules={{ required: true, max: 1095, min: 1 }}
                onFocus={() => expiryRef.current.focus()}
                onKeyDown={e => e.which === 69 && e.preventDefault()}
                variant='outlined'
                margin='normal'
                type='number'
                fullWidth
                name='expiry'
                label='Expiration Date (Max: 1095 days)'
                id='expiry'
                disabled
                error={Object.prototype.hasOwnProperty.call(errors, 'expiry')}
                helperText={
                  (errors.expiry?.type === 'required' && 'Please input scratch value') ||
                  (errors.expiry?.type === 'max' && 'Your input exceed max number') ||
                  (errors.expiry?.type === 'min' && 'Your input less than min number')
                }
              />
              <Button
                type='submit'
                fullWidth
                variant='contained'
                color='primary'
                className={classes.submit}
                disabled={loading || openDialog}
              >
                {
                  loading ? <CircularProgress size={24} /> : 'Submit'
                }
              </Button>
            </form>
            <Dialog
              open={openDialog}
              aria-labelledby='generated-codes'
              disableBackdropClick
              disableEscapeKeyDown
              fullWidth
            >
              <DialogTitle id='customized-dialog-title' onClose={handleCloseDialog}>
                <Typography variant='h6'>
                  Generated Codes
                </Typography>
                <Typography variant='subtitle2'>
                  <Link href={downloadUrl} onClick={handleDownloadGeneratedCodes} underline='always' download='Generated Codes.csv'>
                    Download
                  </Link>
                </Typography>
              </DialogTitle>
              <DialogContent dividers>
                <TableContainer>
                  <StyledTable size='small'>
                    <TableHead>
                      <TableRow>
                        {columns.map((column) => (
                          <StyledTableHeadCell
                            key={column.id}
                          >
                            {column.label}
                          </StyledTableHeadCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {responseData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                        return (
                          <TableRow hover key={row.serialNumber}>
                            {columns.map((column) => {
                              const value = row[column.id]

                              return (
                                <StyledTableBodyCell key={column.id}>
                                  <Tooltip
                                    PopperProps={{
                                      disablePortal: true
                                    }}
                                    open={value === tooltipText}
                                    disableFocusListener
                                    disableHoverListener
                                    disableTouchListener
                                    placement='right'
                                    arrow
                                    title='link copied'
                                  >
                                    <CopyToClipboard text={value} onCopy={(text) => handleOnCopyClipboard(text)}>
                                      <StyledClipboardText highlight={value === tooltipText}>{value}</StyledClipboardText>
                                    </CopyToClipboard>
                                  </Tooltip>
                                </StyledTableBodyCell>
                              )
                            })}
                          </TableRow>
                        )
                      })}
                    </TableBody>
                  </StyledTable>
                </TableContainer>
                <TablePagination
                  rowsPerPageOptions={[10, 25, 100]}
                  component='div'
                  count={responseData.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onChangePage={handleChangePage}
                  onChangeRowsPerPage={handleChangeRowsPerPage}
                />
              </DialogContent>
            </Dialog>
            <Snackbar
              anchorOrigin={{
                vertical: toaster.vertical,
                horizontal: toaster.horizontal
              }}
              open={toaster.open}
              onClose={() => setToaster({ ...toaster, open: false })}
              key={toaster.vertical + toaster.horizontal}
              autoHideDuration={2000}
            >
              {
                error ? <Alert severity='error'>{error.message}</Alert>
                  : <Alert severity='success'>Generate Codes Successfully!</Alert>
              }
            </Snackbar>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  )
}
