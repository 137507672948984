import React, { useContext } from 'react'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/core/styles'
import Avatar from '@material-ui/core/Avatar'
import Typography from '@material-ui/core/Typography'
import { AuthContext } from '../../../../../../context/AuthContext'

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    minHeight: 'fit-content'
  },
  avatar: {
    width: 60,
    height: 60
  },
  name: {
    marginTop: theme.spacing(1)
  }
}))

const Profile = props => {
  const { authState } = useContext(AuthContext)
  const { className, ...rest } = props
  const classes = useStyles()

  const user = {
    name: authState.agentUser && authState.agentUser.agentCode,
    avatar: 'P'
  }

  return (
    <div
      {...rest}
      className={clsx(classes.root, className)}
    >
      <Avatar
        alt='Profile'
        className={classes.avatar}
      >
        {user.avatar}
      </Avatar>
      <Typography
        className={classes.name}
        variant='h5'
      >
        {user.name}
      </Typography>
    </div>
  )
}

export default Profile
