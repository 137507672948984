import React, { useState, useRef, useContext } from 'react'
import Grid from '@material-ui/core/Grid'
import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import CardContent from '@material-ui/core/CardContent'
import TextField from '@material-ui/core/TextField'
import { useForm, Controller } from 'react-hook-form'
import Button from '@material-ui/core/Button'
import API from '../../utils/API'
import { makeStyles } from '@material-ui/core/styles'
import CircularProgress from '@material-ui/core/CircularProgress'
import InputAdornment from '@material-ui/core/InputAdornment'
import IconButton from '@material-ui/core/IconButton'
import Visibility from '@material-ui/icons/Visibility'
import VisibilityOff from '@material-ui/icons/VisibilityOff'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import MuiAlert from '@material-ui/lab/Alert'
import Snackbar from '@material-ui/core/Snackbar'
import Divider from '@material-ui/core/Divider'
import { AuthContext } from '../../context/AuthContext'

const useStyles = makeStyles((theme) => ({
  form: {
    width: '100%' // Fix IE 11 issue.
  },
  submit: {
    margin: theme.spacing(2, 0, 0)
  }
}))

const Alert = (props) => {
  return <MuiAlert elevation={6} variant='filled' {...props} />
}

export default function ChangePassword () {
  const { authDispatch } = useContext(AuthContext)
  const classes = useStyles()
  const { handleSubmit, control, errors, reset } = useForm({
    defaultValues: {
      oldPassword: '',
      newPassword: ''
    },
    validateCriteriaMode: 'all'
  })
  const [loading, setLoading] = useState(false)
  const [loadingChangePass, setLoadingChangePass] = useState(false)
  const [showPassword, setShowPassword] = useState({
    oldPassword: false,
    newPassword: false
  })
  const [openDialog, setOpenDialog] = useState({
    open: false,
    values: null
  })
  const [toaster, setToaster] = useState({
    open: false,
    vertical: 'top',
    horizontal: 'right'
  })
  const [redirectToaster, setRedirectToaster] = useState({
    open: false,
    vertical: 'top',
    horizontal: 'right'
  })
  const [error, setError] = useState(undefined)
  const oldPasswordRef = useRef()
  const newPasswordRef = useRef()

  function onSubmit (values) {
    setLoading(true)
    setOpenDialog({
      open: true,
      values: values
    })
  }

  async function handleChangePassword () {
    setLoadingChangePass(true)
    try {
      const response = await API({
        method: 'POST',
        url: 'agent/changepassword',
        data: {
          oldPassword: openDialog.values.oldPassword,
          newPassword: openDialog.values.newPassword
        }
      })
      setLoading(false)
      setLoadingChangePass(false)
      if (response.data.status === 200) {
        setToaster({ ...toaster, open: true })
        setOpenDialog(prevState => ({
          ...prevState,
          open: false
        }))
        reset()
      }
    } catch (error) {
      setLoading(false)
      setLoadingChangePass(false)
      if (error.response.data.errors.OldPassword) {
        setError({
          name: error.response.data.title,
          message: error.response.data.errors.OldPassword.toString()
        })
      } else {
        setError({
          name: error.response.data.title,
          message: error.response.data.errors.Error.toString()
        })
      }
      setOpenDialog(prevState => ({
        ...prevState,
        open: false
      }))
      setToaster({ ...toaster, open: true })
    }
  }

  return (
    <Grid container>
      <Grid item md={6}>
        <Card>
          <CardHeader
            title='Change Password'
          />
          <Divider />
          <CardContent>
            <form className={classes.form} onSubmit={handleSubmit(onSubmit)}>
              <Controller
                as={<TextField inputRef={oldPasswordRef} />}
                control={control}
                rules={{
                  required: true
                }}
                onFocus={() => oldPasswordRef.current.focus()}
                InputProps={{
                  endAdornment: <InputAdornment position='end'>
                    <IconButton
                      aria-label='toggle password visibility'
                      onClick={() => setShowPassword(prevState => ({
                        ...prevState,
                        oldPassword: !prevState.oldPassword
                      }))}
                      onMouseDown={(e) => e.preventDefault()}
                      edge='end'
                    >
                      {
                        showPassword.oldPassword ? <Visibility /> : <VisibilityOff />
                      }
                    </IconButton>
                  </InputAdornment> //eslint-disable-line
                }}
                name='oldPassword'
                fullWidth
                variant='outlined'
                margin='normal'
                id='oldPassword'
                type={showPassword.oldPassword ? 'text' : 'password'}
                label='Old Password'
                disabled={loading || toaster.open}
                error={Object.prototype.hasOwnProperty.call(errors, 'oldPassword') || !!error}
                helperText={errors.oldPassword?.types.required && 'Please input old password'}
              />
              <Controller
                as={<TextField inputRef={newPasswordRef} />}
                control={control}
                rules={{
                  required: true,
                  minLength: 8,
                  validate: {
                    samePassword: value => value !== oldPasswordRef.current.value || 'Same with old password. Please type new password',
                    upperCase: value => /^(?=.*[A-Z])/.test(value),
                    number: value => /^(?=.*[0-9])/.test(value),
                    special: value => /^(?=.*[!@#$%^&*>+<_-])/.test(value)
                  }
                }}
                onFocus={() => newPasswordRef.current.focus()}
                InputProps={{
                  endAdornment: <InputAdornment position='end'>
                    <IconButton
                      aria-label='toggle password visibility'
                      onClick={() => setShowPassword(prevState => ({
                        ...prevState,
                        newPassword: !prevState.newPassword
                      }))}
                      onMouseDown={(e) => e.preventDefault()}
                      edge='end'
                    >
                      {
                        showPassword.newPassword ? <Visibility /> : <VisibilityOff />
                      }
                    </IconButton>
                  </InputAdornment> //eslint-disable-line
                }}
                name='newPassword'
                fullWidth
                variant='outlined'
                margin='normal'
                id='newPassword'
                type={showPassword.newPassword ? 'text' : 'password'}
                label='New Password'
                disabled={loading || toaster.open}
                error={Object.prototype.hasOwnProperty.call(errors, 'newPassword') || !!error}
                helperText={
                  (errors.newPassword?.types.required && 'Please input new password') ||
                  (errors.newPassword?.types.minLength && 'Must contain at least 8 characters') ||
                  (errors.newPassword?.types.upperCase && 'Must contain at least one uppercase letter') ||
                  (errors.newPassword?.types.number && 'Must contain at least one digit') ||
                  (errors.newPassword?.types.special && 'Must contain at least one special character') ||
                  (errors.newPassword?.types.samePassword)
                }
              />
              <Button
                type='submit'
                fullWidth
                variant='contained'
                color='primary'
                className={classes.submit}
                disabled={loading || toaster.open}
              >
                {
                  loading ? <CircularProgress size={24} /> : 'Submit'
                }
              </Button>
            </form>
          </CardContent>
        </Card>
        <Dialog
          open={openDialog.open}
          onClose={() => setOpenDialog(prevState => ({
            ...prevState,
            open: false
          }))}
          aria-labelledby='alert-dialog-title'
          aria-describedby='alert-dialog-description'
          disableBackdropClick
          disableEscapeKeyDown
        >
          <DialogTitle id='alert-dialog-title'>Confirm New Password</DialogTitle>
          <DialogContent>
            <DialogContentText id='alert-dialog-description'>
              Are you sure you want to change your current password?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                setOpenDialog(prevState => ({
                  ...prevState,
                  open: false
                }))
                setLoading(false)
              }}
            >
              No
            </Button>
            <Button onClick={handleChangePassword} color='primary' autoFocus disabled={loadingChangePass}>
              {
                loadingChangePass ? <CircularProgress size={24} /> : 'Yes'
              }
            </Button>
          </DialogActions>
        </Dialog>
        <Snackbar
          anchorOrigin={{
            vertical: toaster.vertical,
            horizontal: toaster.horizontal
          }}
          open={toaster.open}
          onExited={() => {
            if (!error) {
              setRedirectToaster({ ...redirectToaster, open: true })
            } else {
              setError(undefined)
            }
          }}
          onClose={() => setToaster({ ...toaster, open: false })}
          key='toaster'
          autoHideDuration={3000}
        >
          {
            error ? <Alert severity='error'>{error.message}</Alert>
              : <Alert severity='success'>Password Changed Successfully!</Alert>
          }
        </Snackbar>
        <Snackbar
          anchorOrigin={{
            vertical: redirectToaster.vertical,
            horizontal: redirectToaster.horizontal
          }}
          open={redirectToaster.open}
          onExited={() => authDispatch({
            type: 'LOGOUT'
          })}
          onClose={() => setRedirectToaster({ ...redirectToaster, open: false })}
          key='redirectToaster'
          autoHideDuration={1000}
        >
          <Alert severity='warning'>You will be redirected to login...</Alert>
        </Snackbar>
      </Grid>
    </Grid>
  )
}
