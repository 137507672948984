// utils/API.js
import axios from 'axios'

const baseURL = process.env.REACT_APP_ENDPOINT

const API = axios.create({
  baseURL: baseURL,
  headers: {
    'Content-Type': 'application/json'
  }
})

API.interceptors.request.use(config => {
  const accessToken = window.localStorage.getItem('accessToken')

  if (accessToken) { // if token is present attach it to headers using interceptors
    config.headers.Authorization = `Bearer ${JSON.parse(accessToken)}`
    return config
  } else { // else just return the default headers and delete to make sure token is cleared
    config.headers.Authorization = null
    delete config.headers.Authorization

    return config
  }
}, error => {
  return Promise.reject(error)
})

API.interceptors.response.use(response => {
  // Return a successful response back to the calling service
  return response
}, error => {
  // redirect user to login when token is expired
  if (error.response.status === 401) {
    setTimeout(() => {
      window.alert('Session timeout! You will be redirected to login.')
      window.localStorage.clear()
      window.location.reload()
    }, 1000)
  } else {
    return Promise.reject(error)
  }
})

export default API
