import React, { useContext } from 'react'
import { useHistory } from 'react-router-dom'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/core/styles'
import Divider from '@material-ui/core/Divider'
import Drawer from '@material-ui/core/Drawer'
import DashboardIcon from '@material-ui/icons/Dashboard'
import AccountBalanceIcon from '@material-ui/icons/AccountBalance'
import CreditCardIcon from '@material-ui/icons/CreditCard'
import Profile from './components/Profile/Profile'
import SidebarNav from './components/SidebarNav/SidebarNav'
import Hidden from '@material-ui/core/Hidden'
import LockIcon from '@material-ui/icons/Lock'
import InputIcon from '@material-ui/icons/Input'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import styled from 'styled-components'
import { AuthContext } from '../../../../context/AuthContext'

const useStyles = makeStyles(theme => ({
  drawer: {
    width: 240,
    [theme.breakpoints.up('md')]: {
      marginTop: 64,
      height: 'calc(100% - 64px)'
    }
  },
  root: {
    backgroundColor: theme.palette.white,
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    padding: theme.spacing(2)
  },
  divider: {
    margin: theme.spacing(2, 0)
  },
  nav: {
    marginBottom: theme.spacing(2)
  }
}))

const StyledListSecondaryMenu = styled(List)`
 > li {
   cursor: pointer;

   &:hover {
    background-color: rgba(38, 50, 56, 0.04);
    text-decoration: none;
   }
 }
`

const Sidebar = props => {
  const { authDispatch } = useContext(AuthContext)
  const history = useHistory()
  const { open, variant, onClose, className, ...rest } = props
  const classes = useStyles()

  const pages = [
    {
      title: 'Generate Codes',
      href: '/generate-codes',
      icon: <DashboardIcon />
    },
    {
      title: 'Scratch Card List',
      href: '/scratch-card-list',
      icon: <CreditCardIcon />
    },
    {
      title: 'Transaction List',
      href: '/transaction-list',
      icon: <AccountBalanceIcon />
    }
  ]

  function handleChangePassword () {
    history.push('/change-password')
  }

  function handleSignOut () {
    authDispatch({
      type: 'LOGOUT'
    })
  }

  return (
    <Drawer
      anchor='left'
      classes={{ paper: classes.drawer }}
      onClose={onClose}
      open={open}
      variant={variant}
    >
      <div
        {...rest}
        className={clsx(classes.root, className)}
      >
        <Profile />
        <Divider className={classes.divider} />
        <SidebarNav
          className={classes.nav}
          pages={pages}
        />
        <Divider />
        <Hidden mdUp>
          <StyledListSecondaryMenu>
            <ListItem onClick={handleChangePassword}>
              <ListItemIcon>
                <LockIcon />
              </ListItemIcon>
              Change Password
            </ListItem>
            <ListItem onClick={handleSignOut}>
              <ListItemIcon>
                <InputIcon />
              </ListItemIcon>
              Sign Out
            </ListItem>
          </StyledListSecondaryMenu>
        </Hidden>
      </div>
    </Drawer>
  )
}

export default Sidebar
