import React from 'react'
import AuthRouteWithLayout from '../../components/AuthRouteWithLayout'
import MainLayout from '../Layout/MainLayout'
import GenerateCodes from '../GenerateCodes'
import ScratchCardList from '../ScratchCardList'
import TransactionList from '../TransactionList'
import ChangePassword from '../ChangePassword'

const AuthenticatedRoutes = () => {
  return (
    <>
      <AuthRouteWithLayout
        component={GenerateCodes}
        exact
        layout={MainLayout}
        path='/generate-codes'
      />
      <AuthRouteWithLayout
        component={ScratchCardList}
        exact
        layout={MainLayout}
        path='/scratch-card-list'
      />
      <AuthRouteWithLayout
        component={TransactionList}
        exact
        layout={MainLayout}
        path='/transaction-list'
      />
      <AuthRouteWithLayout
        component={ChangePassword}
        exact
        layout={MainLayout}
        path='/change-password'
      />
    </>
  )
}

export default AuthenticatedRoutes
