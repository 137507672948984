import React from 'react'
import styled from 'styled-components'

const StyledErrorContainer = styled.div`
  text-align: center;

  h1 {
    font-size: 100px;
    padding: 150px 0 0;
    margin: 0;
    line-height: 1;
  }

  p {
    margin: 20px 0;
  }
`

export default function NotFound () {
  return (
    <StyledErrorContainer>
      <h1>404</h1>
      <p>Sorry, the page you have visited does not exist.</p>
    </StyledErrorContainer>
  )
}
