import React, { useState, useRef, useContext } from 'react'
import Avatar from '@material-ui/core/Avatar'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import LockOutlinedIcon from '@material-ui/icons/LockOutlined'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import Container from '@material-ui/core/Container'
import { useForm, Controller } from 'react-hook-form'
import CircularProgress from '@material-ui/core/CircularProgress'
import API from '../../utils/API'
import { AuthContext } from '../../context/AuthContext'
import { Alert } from '@material-ui/lab'
import Paper from '@material-ui/core/Paper'
import Visibility from '@material-ui/icons/Visibility'
import VisibilityOff from '@material-ui/icons/VisibilityOff'
import InputAdornment from '@material-ui/core/InputAdornment'
import IconButton from '@material-ui/core/IconButton'

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(8),
    padding: theme.spacing(2)
  },
  paper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main
  },
  error: {
    width: '100%'
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1)
  },
  submit: {
    margin: theme.spacing(2, 0, 0)
  }
}))

export default function Login (props) {
  const { authDispatch } = useContext(AuthContext)
  const classes = useStyles()
  const [loading, setLoading] = useState(false)
  const { handleSubmit, control, errors } = useForm({
    defaultValues: {
      username: '',
      password: ''
    }
  })
  const [showPassword, setShowPassword] = useState(false)
  const [error, setError] = useState(undefined)
  const usernameRef = useRef()
  const passwordRef = useRef()

  async function onSubmit (values) {
    setLoading(true)

    try {
      const loginResponse = await API({
        method: 'POST',
        url: '/authentication/login',
        data: {
          username: values.username,
          password: values.password
        }
      })
      authDispatch({
        type: 'LOGIN',
        payload: {
          isAuthenticated: true,
          accessToken: loginResponse.data.data,
          agentUser: null
        }
      })
      const agentResponse = await API({
        method: 'GET',
        url: '/agent/summary'
      })
      authDispatch({
        type: 'LOGIN',
        payload: {
          isAuthenticated: true,
          accessToken: loginResponse.data.data,
          agentUser: agentResponse.data.data
        }
      })
    } catch (error) {
      setLoading(false)
      setError({
        name: error.response.data.title,
        message: error.response.data.errors.Error.toString()
      })
    }
  }

  return (
    <Container component='main' maxWidth='xs'>
      <Paper className={classes.root}>
        <div className={classes.paper}>
          <Avatar className={classes.avatar}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography variant='h5'>
            Scratch Card Agent BO
          </Typography>
          {
            error &&
              <Alert severity='error' className={classes.error}>
                {error.message}
              </Alert>
          }
          <form className={classes.form} onSubmit={handleSubmit(onSubmit)}>
            <Controller
              as={<TextField inputRef={usernameRef} />}
              control={control}
              rules={{ required: true }}
              onFocus={() => usernameRef.current.focus()}
              name='username'
              fullWidth
              variant='outlined'
              margin='normal'
              id='username'
              label='Username'
              disabled={loading}
              error={Object.prototype.hasOwnProperty.call(errors, 'username') || !!error}
              helperText={errors.username?.type === 'required' && 'Please input username'}
            />
            <Controller
              as={<TextField inputRef={passwordRef} />}
              control={control}
              rules={{ required: true }}
              onFocus={() => passwordRef.current.focus()}
              InputProps={{
                endAdornment: <InputAdornment position='end'>
                  <IconButton
                    aria-label='toggle password visibility'
                    onClick={() => setShowPassword(prevState => !prevState)}
                    onMouseDown={(e) => e.preventDefault()}
                    edge='end'
                  >
                    {
                      showPassword ? <Visibility /> : <VisibilityOff />
                    }
                  </IconButton>
                  </InputAdornment> //eslint-disable-line
              }}
              name='password'
              fullWidth
              variant='outlined'
              margin='normal'
              id='password'
              type={showPassword ? 'text' : 'password'}
              label='Password'
              disabled={loading}
              error={Object.prototype.hasOwnProperty.call(errors, 'password') || !!error}
              helperText={errors.password?.type === 'required' && 'Please input password'}
            />
            <Button
              type='submit'
              fullWidth
              variant='contained'
              color='primary'
              className={classes.submit}
              disabled={loading}
            >
              {
                loading ? <CircularProgress size={24} /> : 'Log in'
              }
            </Button>
          </form>
        </div>
      </Paper>
    </Container>
  )
}
