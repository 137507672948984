import React, { useContext } from 'react'
import { Route, Redirect } from 'react-router-dom'
import { AuthContext } from '../context/AuthContext'

export default function AuthRouteWithLayout (props) {
  const { authState } = useContext(AuthContext)
  const { layout: Layout, component: Component, ...rest } = props

  return (
    <Route
      {...rest}
      render={props => authState.isAuthenticated ? (
        <Layout>
          <Component {...props} />
        </Layout>
      ) : (<Redirect to='/' />)}
    />
  )
}
