import React, { useState, useContext } from 'react'
import { Link, useHistory } from 'react-router-dom'
import clsx from 'clsx'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import Hidden from '@material-ui/core/Hidden'
import IconButton from '@material-ui/core/IconButton'
import MenuIcon from '@material-ui/icons/Menu'
import InputIcon from '@material-ui/icons/Input'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import RefreshIcon from '@material-ui/icons/Refresh'
import CircularProgress from '@material-ui/core/CircularProgress'
import Tooltip from '@material-ui/core/Tooltip'
import styled from 'styled-components'
import { AuthContext } from '../../../../context/AuthContext'
import API from '../../../../utils/API'

const useStyles = makeStyles(theme => ({
  root: {
    boxShadow: 'none'
  },
  flexGrow: {
    flexGrow: 1
  },
  signOutButton: {
    marginLeft: theme.spacing(1)
  }
}))

const StyledLogo = styled(Link)`
  color: ${props => props.theme.palette.common.white};
  font-size: 16px;
  margin: 0 15px;
  text-decoration: none;
`

const LightTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: theme.shadows[1],
    fontSize: 11
  },
  arrow: {
    color: theme.palette.common.white
  }
}))(Tooltip)

const StyledBalance = styled.span`
  font-size: 16px;
  margin-right: 15px;
  min-width: 90px;
  text-align: center;

  @media (max-width: 600px) {
    font-size: 12px;
    margin-right: 5px;
  }
`

const StyledRefreshButton = styled(RefreshIcon)`
  cursor: pointer;
  font-size: 25px;
  margin-right: 15px;

  &:hover {
    background-color: ${props => props.theme.palette.common.white};
    color: ${props => props.theme.palette.primary.main};
  }

  @media (max-width: 600px) {
    margin-right: 5px;
  }
`

const StyledRefreshIcon = styled(CircularProgress)`
  color: ${props => props.theme.palette.common.white};
  vertical-align: middle;
`

const Topbar = props => {
  const { authState, authDispatch } = useContext(AuthContext)
  const history = useHistory()
  const { className, onSidebarOpen, ...rest } = props
  const classes = useStyles()
  const [anchorEl, setAnchorEl] = useState(null)
  const openAccount = Boolean(anchorEl)
  const currency = authState.agentUser && authState.agentUser.currency === 1 ? 'VND' : 'THB'
  const currencyLocale = authState.agentUser && authState.agentUser.currency === 1 ? 'en-US' : 'th-TH'
  const [loadingBalance, setLoadingBalance] = useState(false)

  function handleMenu (event) {
    setAnchorEl(event.currentTarget)
  }

  function handleClose () {
    setAnchorEl(null)
  }

  function handleLogOut () {
    authDispatch({
      type: 'LOGOUT'
    })
  }

  function handleChangePassword () {
    history.push('/change-password')
    setAnchorEl(null)
  }

  async function handleRefreshButton () {
    // will call agent api to get latest balance
    setLoadingBalance(true)
    try {
      const agentResponse = await API({
        method: 'GET',
        url: '/agent/summary'
      })
      authDispatch({
        type: 'UPDATE_BALANCE',
        payload: {
          endBalance: agentResponse.data.data.balance
        }
      })
      setLoadingBalance(false)
    } catch (error) {
      setLoadingBalance(false)
      console.error(error)
    }
  }

  return (
    <AppBar
      {...rest}
      className={clsx(classes.root, className)}
    >
      <Toolbar>
        <StyledLogo to='/'>
          Agent Back Office
        </StyledLogo>
        <div className={classes.flexGrow} />
        <LightTooltip title='Update Balance' arrow>
          <StyledRefreshButton onClick={handleRefreshButton} />
        </LightTooltip>
        <StyledBalance>
          {
            loadingBalance ? <StyledRefreshIcon size={20} />
              : authState.endBalance || (authState.agentUser && authState.agentUser.balance) ? new Intl.NumberFormat(currencyLocale, { minimumFractionDigits: 2, style: 'currency', currency: currency })
                .format(authState.endBalance || (authState.agentUser && authState.agentUser.balance)) : new Intl.NumberFormat(currencyLocale, { minimumFractionDigits: 2, style: 'currency', currency: currency }).format(0)
          }
        </StyledBalance>
        <Hidden smDown>
          <IconButton
            className={classes.signOutButton}
            color='inherit'
            onClick={handleMenu}
          >
            <InputIcon />
          </IconButton>
        </Hidden>
        <Menu
          id='menu-appbar'
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right'
          }}
          keepMounted
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right'
          }}
          open={openAccount}
          onClose={handleClose}
        >
          <MenuItem onClick={handleChangePassword}>Change Password</MenuItem>
          <MenuItem onClick={handleLogOut}>Sign Out</MenuItem>
        </Menu>
        <Hidden mdUp>
          <IconButton
            color='inherit'
            onClick={onSidebarOpen}
          >
            <MenuIcon />
          </IconButton>
        </Hidden>
      </Toolbar>
    </AppBar>
  )
}

export default Topbar
