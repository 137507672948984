import React, { useState, useCallback, useEffect } from 'react'
import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField'
import MenuItem from '@material-ui/core/MenuItem'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import Paper from '@material-ui/core/Paper'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TablePagination from '@material-ui/core/TablePagination'
import TableRow from '@material-ui/core/TableRow'
import API from '../../utils/API'
import IconButton from '@material-ui/core/IconButton'
import FirstPageIcon from '@material-ui/icons/FirstPage'
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft'
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight'
import LastPageIcon from '@material-ui/icons/LastPage'
import CircularProgress from '@material-ui/core/CircularProgress'
import moment from 'moment'
import { KeyboardDatePicker } from '@material-ui/pickers'
import styled from 'styled-components'

const StyledForm = styled.form`
  display: flex;
  margin-bottom: 20px;
  padding: 20px 10px 0;
  width: 100%;

  > .MuiTextField-root {
    margin: ${props => props.theme.spacing(1)}px;
    width: 100% !important;
  }

  @media (max-width:767px) {
    flex-wrap: wrap;
  }
`

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%'
  },
  container: {
    maxHeight: 440
  }
}))

const useStylesPagination = makeStyles((theme) => ({
  root: {
    flexShrink: 0,
    marginLeft: theme.spacing(2.5)
  }
}))

const TablePaginationActions = (props) => {
  const classes = useStylesPagination()
  const theme = useTheme()
  const { count, page, rowsPerPage, onChangePage } = props

  const handleFirstPageButtonClick = (event) => {
    onChangePage(event, 0)
  }

  const handleBackButtonClick = (event) => {
    onChangePage(event, page - 1)
  }

  const handleNextButtonClick = (event) => {
    onChangePage(event, page + 1)
  }

  const handleLastPageButtonClick = (event) => {
    onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1))
  }

  return (
    <div className={classes.root}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label='first page'
      >
        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label='previous page'>
        {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label='next page'
      >
        {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label='last page'
      >
        {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </div>
  )
}

export default function TransactionList () {
  const classes = useStyles()
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [filters, setFilters] = useState({
    type: 0,
    startDate: moment(),
    endDate: moment()
  })
  const [responseData, setResponseData] = useState({
    data: [],
    pageIndex: 1,
    pageSize: 10,
    total: 0
  })
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(undefined)

  const columns = [
    { id: 'id', label: 'ID' },
    { id: 'transactionType', label: 'Type' },
    { id: 'currency', label: 'Currency' },
    {
      id: 'amount',
      label: 'Amount',
      align: 'right'
    },
    {
      id: 'endBalance',
      label: 'End Balance',
      align: 'right'
    },
    {
      id: 'createdTime',
      label: 'Updated Time'
    }
  ]

  const transactionTypeOptions = [
    {
      label: 'All',
      value: 0
    },
    {
      label: 'Purchase',
      value: 1
    },
    {
      label: 'Refund',
      value: 2
    },
    {
      label: 'TopUp',
      value: 3
    },
    {
      label: 'Settlement',
      value: 4
    }
  ]

  function handleChangePage (event, newPage) {
    setPage(newPage)
  }

  function handleChangeRowsPerPage (event) {
    setRowsPerPage(+event.target.value)
    setPage(0)
  }

  function handleChangeFilters (e) {
    setFilters({
      ...filters,
      [e.target.name]: e.target.value
    })
    // this will make sure every filter call, setting the page and row page to first page
    setPage(0)
    setRowsPerPage(10)
  }

  function handleDateChange (name, date) {
    setFilters({
      ...filters,
      startDate: name === 'startDate' ? date : filters.startDate,
      endDate: name === 'endDate' ? date : filters.endDate
    })
    // this will make sure every filter call, setting the page and row page to first page
    setPage(0)
    setRowsPerPage(10)
  }

  function displayTypeID (row) {
    if (row.transactionType === 'Purchase' || row.transactionType === 'Refund') {
      return row.reference
    } else if (row.transactionType === 'TopUp' || row.transactionType === 'Settlement') {
      return row.transactionId
    }
  }

  const getTransactionList = useCallback(async () => {
    setLoading(true)
    try {
      const response = await API({
        method: 'POST',
        url: '/scratchcardtransaction/search',
        data: {
          type: filters.type === 0 ? undefined : filters.type,
          startDate: filters.startDate.startOf('day').toDate(),
          endDate: filters.endDate.endOf('day').toDate(),
          pageIndex: page + 1,
          pageSize: rowsPerPage
        }
      })
      setResponseData(response.data)
      setLoading(false)
    } catch (error) {
      setError(error)
      setLoading(false)
    }
  }, [page, rowsPerPage, filters])

  useEffect(() => {
    getTransactionList()
  }, [page, rowsPerPage, filters, getTransactionList])

  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <Paper elevation={2} className={classes.root}>
          <StyledForm>
            <KeyboardDatePicker
              autoOk
              variant='inline'
              inputVariant='outlined'
              label='Start Date'
              format='DD/MM/YYYY'
              value={filters.startDate}
              InputAdornmentProps={{ position: 'start' }}
              onChange={date => handleDateChange('startDate', date)}
              disabled={loading}
            />
            <KeyboardDatePicker
              autoOk
              variant='inline'
              inputVariant='outlined'
              label='End Date'
              format='DD/MM/YYYY'
              value={filters.endDate}
              InputAdornmentProps={{ position: 'start' }}
              onChange={date => handleDateChange('endDate', date)}
              disabled={loading}
            />
            <TextField
              margin='normal'
              id='type'
              variant='outlined'
              label='Transaction Type'
              name='type'
              value={filters.type}
              onChange={handleChangeFilters}
              select
              disabled={loading}
            >
              {
                transactionTypeOptions.map((option, i) => (
                  <MenuItem key={i} value={option.value}>
                    {
                      option.label
                    }
                  </MenuItem>
                ))
              }
            </TextField>
          </StyledForm>
          <TableContainer className={classes.container}>
            <Table stickyHeader aria-label='sticky table'>
              <TableHead>
                <TableRow>
                  {columns.map((column) => (
                    <TableCell
                      key={column.id}
                      align={column.align}
                    >
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {
                  loading ? <TableRow>
                    <TableCell colSpan={6} align='center'>
                      <CircularProgress />
                    </TableCell>
                  </TableRow> : (responseData.data.length <= 0 || error) // eslint-disable-line
                    ? <TableRow>
                      <TableCell colSpan={6} align='center'>
                        No Results Found!
                      </TableCell>
                  </TableRow> : responseData.data.map((row, i) => { // eslint-disable-line
                      return (
                        <TableRow hover role='checkbox' tabIndex={-1} key={i}>
                          {columns.map((column) => {
                            let cellValue

                            if (column.id === 'id') {
                              cellValue = displayTypeID(row)
                            } else if (column.id === 'currency') {
                              cellValue = row.currency
                            } else if (column.id === 'amount') {
                              cellValue = new Intl.NumberFormat(`${row.currency === 'VND' ? 'en-US' : 'th-TH'}`, { minimumFractionDigits: 2, style: 'currency', currency: row.currency })
                                .format(row.amount)
                            } else if (column.id === 'endBalance') {
                              cellValue = new Intl.NumberFormat(`${row.currency === 'VND' ? 'en-US' : 'th-TH'}`, { minimumFractionDigits: 2, style: 'currency', currency: row.currency })
                                .format(row.endBalance)
                            } else if (column.id === 'createdTime' && row.createdTime) {
                              cellValue = moment(row.createdTime).format('YYYY-MM-DD HH:mm:ss')
                            } else {
                              cellValue = row[column.id]
                            }

                            return (
                              <TableCell key={column.id} align={column.align}>
                                {cellValue}
                              </TableCell>
                            )
                          })}
                        </TableRow>
                      )
                    })
                }
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 25, 100]}
            component='div'
            count={responseData.total}
            rowsPerPage={responseData.pageSize}
            page={responseData.pageIndex - 1}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
            ActionsComponent={TablePaginationActions}
          />
        </Paper>
      </Grid>
    </Grid>
  )
}
