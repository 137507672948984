import React, { useReducer, useEffect, useCallback } from 'react'
import Login from '../Login'
import AuthenticatedRoutes from '../AuthenticatedRoutes'
import NotFound from '../NotFound'
import { CssBaseline } from '@material-ui/core'
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom'
import GlobalStyles from '../../assets/styles/GlobalStyles'
import { AuthContext } from '../../context/AuthContext'
import { ThemeProvider } from '@material-ui/core/styles'
import { ThemeProvider as StyledThemeProvider } from 'styled-components'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import MomentUtils from '@date-io/moment'
import theme from '../../theme'
import jwtDecode from 'jwt-decode'

const initialState = {
  isAuthenticated: !!(JSON.parse(window.localStorage.getItem('accessToken')) && JSON.parse(window.localStorage.getItem('agentUser'))),
  accessToken: JSON.parse(window.localStorage.getItem('accessToken')) || null,
  agentUser: JSON.parse(window.localStorage.getItem('agentUser')) || null,
  endBalance: JSON.parse(window.localStorage.getItem('endBalance')) || null
}

const authReducer = (state, action) => {
  switch (action.type) {
    case 'LOGIN':
      window.localStorage.setItem('accessToken', JSON.stringify(action.payload.accessToken))
      window.localStorage.setItem('agentUser', JSON.stringify(action.payload.agentUser))
      return {
        ...state,
        isAuthenticated: true,
        accessToken: action.payload.accessToken,
        agentUser: action.payload.agentUser
      }
    case 'LOGOUT':
      window.localStorage.clear()
      return {
        ...state,
        isAuthenticated: false,
        accessToken: null,
        agentUser: null,
        endBalance: null
      }
    case 'UPDATE_BALANCE':
      window.localStorage.setItem('endBalance', JSON.stringify(action.payload.endBalance))
      return {
        ...state,
        endBalance: action.payload.endBalance
      }
    default:
      return state
  }
}

export default function App () {
  const [authState, authDispatch] = useReducer(authReducer, initialState)

  const checkIfTokeniSValid = useCallback(
    () => {
      try {
        const decodedJwtToken = jwtDecode(authState.accessToken)
        const currentTime = new Date().getTime() / 1000

        if (currentTime > decodedJwtToken && decodedJwtToken.exp) { // check token if expired
          window.alert('Session timeout! You will be redirected to login.')
          authDispatch({
            type: 'LOGOUT'
          })
        }
      } catch (error) { // InvalidTokenError
        window.alert('Session timeout! You will be redirected to login.')
        authDispatch({
          type: 'LOGOUT'
        })
      }
    },
    [authState.accessToken]
  )

  useEffect(() => {
    if (authState.accessToken) {
      checkIfTokeniSValid()
    }
  }, [authState.accessToken, checkIfTokeniSValid])

  return (
    <ThemeProvider theme={theme}>
      <StyledThemeProvider theme={theme}>
        <AuthContext.Provider value={{ authState, authDispatch }}>
          <MuiPickersUtilsProvider utils={MomentUtils}>
            <CssBaseline />
            <GlobalStyles />
            <Router>
              <Switch>
                <Route exact path='/' render={() => <Redirect to='/login' />} />
                <Route path='/login' render={() => authState.isAuthenticated ? <Redirect to='/generate-codes' /> : <Login />} />
                <AuthenticatedRoutes />
                <Route path='*' component={NotFound} />
              </Switch>
            </Router>
          </MuiPickersUtilsProvider>
        </AuthContext.Provider>
      </StyledThemeProvider>
    </ThemeProvider>
  )
}
