import React, { useRef, useContext } from 'react'
import IdleTimer from 'react-idle-timer'
import { AuthContext } from '../context/AuthContext'

function IdleTimerComponent () {
  const { authDispatch } = useContext(AuthContext)
  const idleTimerRef = useRef(null)

  function handleOnIdle () { // called when timeout passed
    window.alert('Session timeout! You will be redirected to login.')
    authDispatch({
      type: 'LOGOUT'
    })
  }

  return (
    <>
      <IdleTimer
        ref={idleTimerRef}
        timeout={1800 * 1000}
        onIdle={handleOnIdle}
      />
    </>
  )
}

export default IdleTimerComponent
